import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';

import Content, { HTMLContent, HTMLContentAST } from '../components/Content';
import ContentFooter from '../components/ContentFooter';
import Layout from '../components/Layout';
import Title from '../components/Title';
import * as styles from './BlogPost.module.scss';

export const BlogPostTemplate = ({
  layout,
  content,
  contentComponent,
  title,
  subtitle,
  attribution,
  helmet,
  date,
  image,
  author,
}) => {
  const PostContent = contentComponent || Content;
  const PageLayout = layout || Layout;

  return (
    <PageLayout backdropThree>
      <article>
        <div className={`after-nav ${styles.mainContent}`}>
          {helmet || ''}
          <div className={styles.blogHeader}>
            <header className={`${styles.blogSection} ${styles.animatedDate}`}>
              <Title accentBar={!subtitle} text={title} size="1" animated />
              {!!subtitle ? (
                <>
                  <br />
                  <Title
                    accentBar
                    className={styles.subtitle}
                    text={subtitle}
                    element="p"
                    animated
                  />
                </>
              ) : null}
              <time>
                <p>{date}</p>
              </time>
            </header>
          </div>
          <div className={styles.blogImage}>
            <figure
              className={`${styles.blogSection} ${styles.animatedThumbnail}`}
            >
              {image != null ? (
                image.childImageSharp != null ? (
                  <div>
                    <GatsbyImage
                      image={image.childImageSharp.gatsbyImageData}
                      alt=""
                    />
                    <figcaption>
                      <HTMLContent
                        content={attribution}
                        className={styles.imgAttribution}
                      />
                    </figcaption>
                  </div>
                ) : (
                  <div>
                    <img alt="" src={image} />
                    <HTMLContent content={attribution} />
                  </div>
                )
              ) : (
                <div />
              )}
            </figure>
          </div>
          <div className={styles.blogContent}>
            <section className={styles.blogSection}>
              <p>
                <b>By {author ? author.frontmatter.author_id : ''}</b>
              </p>
              <main className={styles.articleContent}>
                <PostContent content={content} />
              </main>
              <div className={styles.authorSection}>
                {(author && author.frontmatter.profilePicture) != null ? (
                  author.frontmatter.profilePicture.childImageSharp != null ? (
                    <GatsbyImage
                      image={
                        author.frontmatter.profilePicture.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Author"
                      className={styles.authorImage}
                    />
                  ) : (
                    <img
                      alt="Author"
                      className={styles.authorImage}
                      src={author.frontmatter.profilePicture}
                    />
                  )
                ) : (
                  <div className={styles.authorImageReplacement} />
                )}
                <div>
                  <div>
                    <div className={styles.authorName}>
                      Article by {author ? author.frontmatter.author_id : ''}
                    </div>
                    {/* <div className={styles.authorBio}>{author.frontmatter.bio}</div> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <ContentFooter text="More articles" target="/blog" />
        </div>
      </article>
    </PageLayout>
  );
};

BlogPostTemplate.propTypes = {
  layout: PropTypes.func,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  attribution: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  image: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
};

BlogPostTemplate.defaultProps = {
  layout: null,
  contentComponent: null,
  subtitle: '',
  attribution: '',
  date: 'DD MMM YYYY',
  helmet: null,
};

class BlogPost extends React.Component {
  constructor(props) {
    super(props);
    this.popDownNavBar = React.createRef();
    this.post = props.data.markdownRemark;
  }

  componentDidMount() {
    document.documentElement.classList.toggle('noscroll', false);
  }

  render() {
    return (
      <BlogPostTemplate
        content={this.post.htmlAst}
        contentComponent={HTMLContentAST}
        date={this.post ? this.post.frontmatter.date : ''}
        image={this.post ? this.post.frontmatter.image : null}
        attribution={this.post.frontmatter.attribution || undefined}
        author={this.post ? this.post.frontmatter.author : null}
        helmet={
          <Helmet titleTemplate="%s | Lab3 Apps">
            <title>{`${this.post ? this.post.frontmatter.title : ''}`}</title>
            <meta
              name="description"
              content={`${this.post ? this.post.frontmatter.description : ''}`}
            />
            <meta property="og:title" content={this.post.frontmatter.title} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="/" />
            <meta
              property="og:image"
              content={`https://lab3apps.com${this.post.frontmatter.image.childImageSharp.gatsbyImageData.src}`}
            />
            <meta
              property="og:description"
              content={this.post.frontmatter.description}
            />
          </Helmet>
        }
        tags={this.post ? this.post.frontmatter.tags : ''}
        title={this.post ? this.post.frontmatter.title : ''}
        subtitle={this.post.frontmatter.subtitle || undefined}
      />
    );
  }
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        attribution
        title
        subtitle
        description
        tags
        author {
          frontmatter {
            author_id
            bio
            profilePicture {
              childImageSharp {
                gatsbyImageData(
                  width: 300
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(
              width: 700
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
