// extracted by mini-css-extract-plugin
export var animatedDate = "BlogPost-module--animated-date--bJbty";
export var animatedThumbnail = "BlogPost-module--animated-thumbnail--37jF+";
export var articleContent = "BlogPost-module--article-content--guy8G";
export var authorImage = "BlogPost-module--author-image--sC7Vn";
export var authorImageReplacement = "BlogPost-module--author-image-replacement--NmR4s";
export var authorName = "BlogPost-module--author-name--539g2";
export var authorSection = "BlogPost-module--author-section--iwCNR";
export var blogContent = "BlogPost-module--blog-content--Hw3cl";
export var blogHeader = "BlogPost-module--blog-header--g8IyY";
export var blogImage = "BlogPost-module--blog-image--jolx+";
export var blogSection = "BlogPost-module--blog-section--Db34T";
export var fadeIn = "BlogPost-module--fade-in--O5cQv";
export var fadeInThumbnail = "BlogPost-module--fade-in-thumbnail--kan12";
export var imgAttribution = "BlogPost-module--img-attribution--0QfYX";
export var mainContent = "BlogPost-module--main-content--kloUx";
export var subtitle = "BlogPost-module--subtitle--4t3fN";